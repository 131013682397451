<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="widjet">
          <div class="widjethdr dispflex">
            <span class="has-float-label" >
            <select
             @change="changeTerm"
              class="form-control form-input"
              id="deptlistid"
              v-model="selectTerm"
            >
              <option :value="null">-- Select--</option>
              <option  
                v-for="(item, index) in termList"
                :key="index"
                :value="item._id"
              >
                {{ item.name }}
              </option>
            </select>
            <label for="clslistid">Select Term </label>
          </span>
            <div style="width: auto">
              
             
            
            </div>
            <div style="margin-top: 10px;margin-right: 167px;" class="flexitm">
              
            <span style="font-size: 20px;color: #000;margin-left: 105px;"> Mark</span>
          <!-- Quarterly -->
          <span style="position: absolute;
    top: 22px;
    right: 49%;">
            <label class="switchNew">
              <input  type="checkbox" @input.prevent="updateContributionType(contributionType)" v-model="contributionType" checked>
              <span class="sliderNew round"></span>
            </label>
          </span>
          <span style="font-size: 20px;color: #000;margin-left: 66px;"> Grade</span>
          </div>
            <div style="margin-top: 10px" class="flexitm">
              <button
                style="width: 61%"
                type="button"
                class="btn btnsml"
                @click.prevent="showColumnPopup"
              >
                Add Column
              </button>
              <button
              :disabled="editColumnList.length == 0"
              v-if="!contributionType"
                style="width: 30%"
                type="button"
                class="btn btnsml"
                @click.prevent="saveStudentContribution"
              >
                Save
              </button>
              <button
              :disabled="editColumnList.length == 0"
                v-else
                style="width: 30%"
                type="button"
                class="btn btnsml"
                @click.prevent="saveStudentGradeContribution"
              >
                Save
              </button>
            </div>
          </div>
          <div class=" tbllist" v-if="studentContributionList.length > 0">
            <div class="crtfeeplancont" style="padding: 0 13px" v-if="!contributionType">
              <div
                class="widjethdr dispflex"
                style="align-items: baseline; padding: 10px 0"
              ></div>
              <form
            data-vv-scope="crtAdhocFeeValidate"
            id="crtfeeplans"
            accept-charset="utf-8"
          >
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Student Name</div>
                  <div class="cell" v-for="(value, index) in editColumnList" :key="index">{{  value.label + " ( " + value.totalmark + " )" }}</div>
                 
                </div>
                <div
                  class="resrow"  
                  v-for="(item, index) in studentContributionList"
                  :key="index"
                >
                  <div class="cell">{{ getStudentName(item.studentId) }}</div>
                  <div class="cell" v-for="(cols, indexcol) in editColumnList" :key="indexcol" >
                    <input
                  v-model="item[cols.value]"
                  type="text"
                  :max="cols.totalmark"
                  min="1"
                  @input="handleInput(index, indexcol,cols.value)"
                  v-validate="{ max_value: cols.totalmark }"
                  class="form-control"
                  id="myNumber"
                  :name="index + 'name' + indexcol"
                 
                 
                 
                  
                />
                <!-- @wheel.prevent="handleWheel" -->
                <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'name' + indexcol}`)"> Min : 1, Max: {{  cols.totalmark  }}</span>
               
                  </div>
                </div>
              </div>
            </form>
            </div>
            <div class="crtfeeplancont" style="padding: 0 13px" v-else>
              <div
                class="widjethdr dispflex"
                style="align-items: baseline; padding: 10px 0"
              ></div>
              <form
            data-vv-scope="crtAdhocFeeValidate"
            id="crtfeeplans"
            accept-charset="utf-8"
          >
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Student Name</div>
                  <div class="cell" v-for="(value, index) in editColumnList" :key="index">{{  value.label  }}<span v-if="!contributionType">{{  " ( " + value.totalmark + " )" }}</span></div>
                 
                </div>
                <div
                  class="resrow"  
                  v-for="(item, index) in studentContributionList"
                  :key="index"
                >
                  <div class="cell">{{ getStudentName(item.studentId) }}</div>
                  <div class="cell" v-for="(cols, indexcol) in editColumnList" :key="indexcol" >
                    <!-- <input
                  v-model="item[cols.value]"
                  type="text"
                  :max="cols.totalmark"
                  min="1"
                  @input="handleInput(index, indexcol,cols.value)"
                  v-validate="{ max_value: cols.totalmark }"
                  class="form-control"
                  id="myNumber"
                  :name="index + 'name' + indexcol"
                 
                 
                 
                  
                /> -->
                <select
                          name="assType"
                          class="form-control form-input"
                          id="assType"
                          v-model="item[cols.value]"

                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(data, index) in allGradeList"
                            :key="index"
                            :value="data.grade"
                          >
                            {{ data.grade }}
                          </option>
                        </select>
                <!-- @wheel.prevent="handleWheel" -->
                <span class="required" v-if="errors.first(`crtAdhocFeeValidate.${index + 'name' + indexcol}`)"> Min : 1, Max: {{  cols.totalmark  }}</span>
               
                  </div>
                </div>
              </div>
            </form>
            </div>
          </div>
          <div class="widjet" v-else style="height: 235px">
            <div style="padding-top: 92px">
              <!-- <span style="margin-left: 42%;" v-if="isLoader">Select Class And ClassRoom</span> -->
              <span style="margin-left: 42%"
                >Please Add Contribution Headers...</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="addColumnPopUp" style="width: 100%" no-close-on-backdrop no-close-on-esc>
      <div class="dispflex" style="margin-right: 2%; margin-left: 1%">
        <div class="dk_icon" style="width: 36%">
        <span>Total Contribution : 100</span>
        <span style="margin-left: 11px;">Exam Contribution : {{ getContributionMarks }}</span>
        </div>

        <div class="text-right dk_iconsml widjetcontent">
          <button :disabled="columnList.length == 3"
            style="margin-left: 6px"
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="addColumnEdit"
          >
            <span>Add</span>
          </button>
          <button @click.prevent="closePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Close</span>
          </button>
          <!-- <button
            style="width: 47%; margin-left: 5px"
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="saveContribution"
          >
            <span>Save</span>
          </button> -->
        </div>
      </div>
      <div class="tbllist mt-1">
        <div class="crtfeeplancont" style="padding: 0 13px">
          <div
            class="widjethdr dispflex"
            style="align-items: baseline; padding: 10px 0"
          ></div>
          <div class="restable">
            <div class="resrow resheader">
              <!-- <div class="cell">Type</div> -->
              <div class="cell">Label <span class="required">*</span></div>
              <div class="cell">Total Marks <span class="required">*</span></div>
              <div class="cell">Contribution % <span class="required">*</span></div>

              <div class="cell">Action</div>
            </div>
            <div
              class="resrow"
              v-for="(data, index) in columnList"
              :key="index"
            >
              <div class="cell">
                <input
                  :disabled="data.isStandard"
                  v-model="data.label"
                  type="text"
                  class="form-control"
                  id="stdFirstName"
                />
              </div>
              <div class="cell">
                <input
                  :disabled="data.isStandard"
                  v-model="data.totalmark"
                  type="number"
                  class="form-control"
                  id="stdFirstName"
                />
              </div>
              <div class="cell">
                <input
                  :disabled="data.isStandard"
                  v-model="data.contribution"
                  type="number"
                  class="form-control"
                  id="stdFirstName"
                />
              </div>

              <div class="cell">
                <button v-if="data._id"
                 
                 
                
                style="width: 51%; margin-left: 5px;padding-top: 2px;"
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="updateContribution(data,index)"
          >
            <span>Update</span>
          </button>
                <button v-else
            style="width: 49%; margin-left: 5px;padding-top: 2px;"
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="saveContribution(data,index)"
          >
            <span>Save</span>
          </button>
                <button style="margin-left: 3px;"
                  @click.prevent="removeColumn(data,index)"
                  :disabled="data.isStandard"
                  class="btn round orange tooltipt"
                >
                  <i class="fas fa-minus-circle"></i
                  ><span class="tooltiptext">Remove</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";

import secureUI from "../../../utils/secureUI";
import ViService from "@/services/ViService";
import { eventBus } from "../../../event-bus";

export default {
  name: "AppTeacherAcademicManagementAssignment",
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
  },
  data() {
    return {
      studentContributionList: [],
      columnList: [],
      studentList: [],
      editColumnList: [],
      contributionType: false,
      allGradeList: [],
      termList: [],
      selectTerm: null


    };
  },
  created() {
    this.getClassTerm();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    getContributionMarks() {
      let data = this.columnList.filter( x => x._id)
      if(data.length > 0){
        const total = data.reduce((accumulator, currentObject) => {

         return Number(accumulator) + Number(currentObject.contribution);
        }, 0);

        return 100 - total
      }else{
        return 100
      }
      // var brwHeight = window.innerHeight;
      // return {
      //   height: brwHeight - 90 + "px",
      // };
    },
  },

  methods: {
    async getClassTerm() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/class/getAllTerm?classId=${this.classId}`,
          userData.token
        );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);

          this.termList = data || [];
          
          if (this.termList.length > 0) {

            if (this.termList.length == 1) {
            this.selectTerm = this.termList[0]._id;

            this.getContributionHeaders()
            this.getGradeSetupDetails(),
            this.getContributionAction()

           // this.getExamAcademicInfo(this.termList[0]._id);
           
            
            }else{
            let currentDate = new Date()

            let checkData = this.termList.filter( x => (currentDate >= new Date(x.startDate)) && (currentDate <= new Date(x.endDate)))
            if(checkData.length > 0){

              this.selectTerm = checkData[0]._id;
            
              this.getContributionHeaders()
              this.getGradeSetupDetails(),
              this.getContributionAction()

            }else{
              this.selectTerm = this.termList[0]._id;
             
              this.getContributionHeaders()
              this.getGradeSetupDetails(),
              this.getContributionAction()
             
            }
           
            }
          }

        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    handleInput(rowIndex,cellIndex,value) {

      const data = this.studentContributionList[rowIndex][value];
      this.studentContributionList[rowIndex][value] = data.replace(/\D/g, '');
     
    },
    async changeTerm(){
           await this.getContributionHeaders()
           await this.getGradeSetupDetails(),
           await this.getContributionAction()
    },
    async getGradeSetupDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
       
        const response = await ViService.viXPost(
          "/academic/getGradeSetupDetails",
          { type: 'all'},
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          const data = secureUI.secureGet(response.data);
          this.allGradeList = data.gradeMarkList
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
  
    getStudentName(id){
      let details = this.studentList.find( x => x._id == id)
      if(details && details.firstName){
        return details.firstName + ' ' + details.lastName
      }else{
        return ''
      }
    },
    showColumnPopup() {
      this.$bvModal.show("addColumnPopUp");
      this.columnList = [ ...this.editColumnList ]
      
    },
    closePopUp(){
      this.$bvModal.hide("addColumnPopUp");
      this.columnList = []
    },
    addColumnEdit() {
      this.columnList.push({
        label: "",
        value: "",
        totalmark: null,
        contribution: null,
        isStandard: false,
        class: this.classId,
      });
    },
    handleWheel(event) {
      event.preventDefault();
    },
   
    async updateContributionType(actionType) {
      
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
 
        let obj = {
          class: this.classId,
          isMark: false,
          isGrade: false
        }

        if(actionType){
          obj.isMark = true,
          obj.isGrade = false
        }else{
          obj.isMark = false,
          obj.isGrade = true
        }
        
       
        const response = await ViService.viXPost(
          "/academic/saveContributionType",
          obj,
          userData.token
        );

        if (response.isSuccess) {
         // this.$toasted.success(response.message);
          this.getContributionAction()
        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    async removeColumn(data,index) {
       
     console.log("data",data)
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

         if(data._id){
        const response = await ViService.viXPost(
          "/academic/removeContribution",
          data,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.columnList.splice(index, 1);
          this.$emit('childEvent', 'Hello from Child!');
          this.getContributionHeaders();
          // this.getStudentContributions()
          // this.getStudentGradeContributions()
          this.getContributionAction()

        } else {
          this.$toasted.error(response.message);
        }
         }else{
           this.columnList.splice(index, 1);

         }
     
    }
    },
    async updateContribution(data,index) {
       

       let userData = secureUI.sessionGet("user");
 
       if (!userData) {
         this.$toasted.error("Please login and do the action");
         this.$router.push("/login");
       } else {
 
        if(data.label){
        if(data.totalmark){
          if(data.contribution){
            if (Number(data.contribution) <= this.getContributionMarks) {

              data.value = this.formatApiName(data.label);

              const response = await ViService.viXPost(
                "/academic/updateContribution",
                data,
                userData.token
              );

              if (response.isSuccess) {
                this.$toasted.success(response.message);
                this.$emit('childEvent', 'Hello from Child!');
                await this.getContributionHeaders();
                // this.getStudentContributions()
                // this.getStudentGradeContributions()
                this.getContributionAction()
              } else {
                this.$toasted.error(response.message);
              }
       } else {
        this.$toasted.error('Please Enter Contribution Percentage less than Exam Contribution');
      }
      }else{
        this.$toasted.error('Please Enter Contribution');
      }
      }else{
        this.$toasted.error('Please Enter Total Marks');
      }
       }else{
        this.$toasted.error('Please Enter label');
      }
     }
     },
    formatApiName(apiName) {
      const lastThreeDigit = apiName.substring(apiName.length - 3);
      // check the last three digit contains __c
      if (lastThreeDigit == "__c") {
        return apiName.replace(/\s/g, "_").toLowerCase();
      }

      return (apiName.replace(/\s/g, "_") + "__c").toLowerCase();
    },
    async getContributionHeaders() {
      
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
          class: this.classId,
          term: this.selectTerm
        }
       
        const response = await ViService.viXPost(
          "/academic/getContributionHeaders",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.editColumnList = []
          this.$toasted.success(response.message);
          
          if(response.data.length > 0){
             this.editColumnList = response.data
            // this.$emit('childEvent', 'Hello from Child!');

           //  await eventBus.$emit("callOverallPerformance", 'check');

          }

          this.columnList = [ ...this.editColumnList ]

         // editColumnList
          // this.getExamAcademicInfo(this.selectTerm);
        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    async getStudentContributions() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
          class: this.classId,
          section: this.classRoom,
          term: this.selectTerm
        }
       
        const response = await ViService.viXPost(
          "/academic/getStudentContributions",
          obj,
          userData.token
        );
        if (response.isSuccess) {   
          this.studentContributionList = response.data.contributions
          this.studentList = response.data.students

        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    async getStudentGradeContributions() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
          class: this.classId,
          section: this.classRoom,
          term: this.selectTerm
        }
       
        const response = await ViService.viXPost(
          "/academic/getStudentGradeContributions",
          obj,
          userData.token
        );

        if (response.isSuccess) {   
          this.studentContributionList = response.data.contributions
          this.studentList = response.data.students

        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    async getContributionAction() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
          class: this.classId,
          section: this.classRoom
        }
       
        const response = await ViService.viXPost(
          "/academic/getContributionAction",
          obj,
          userData.token
        );

        if (response.isSuccess) {   

           if(response.data.isMark){
            this.contributionType = false,

            this.getStudentContributions()
           }
           if(response.data.isGrade){
            this.contributionType = true,

            this.getStudentGradeContributions()
           }

        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    async saveContribution(data,index) {
      
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
       if(data.label){
        if(data.totalmark){
          if(data.contribution){
            if (Number(data.contribution) <= this.getContributionMarks) {
             data.value = this.formatApiName(data.label);


        data['term'] = this.selectTerm

        const response = await ViService.viXPost(
          "/academic/contribution/save",
          data,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.$emit('childEvent', 'Hello from Child!');
          this.getContributionHeaders();
        } else {
          this.$toasted.error(response.message);
        }
      } else {
        this.$toasted.error('Please Enter Contribution Percentage less than Exam Contribution');

}
      }else{
        this.$toasted.error('Please Enter Contribution');
      }
      }else{
        this.$toasted.error('Please Enter Total Marks');
      }
       }else{
        this.$toasted.error('Please Enter label');
      }
    }
    },
    async saveStudentContribution() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid){
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
      
       

        const response = await ViService.viXPost(
          "/academic/studentcontribution/save",
          this.studentContributionList,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.$emit('childEvent', 'Hello from Child!');
          this.getContributionAction()
          // this.getStudentContributions()
          // this.getStudentGradeContributions()
        } else {
          this.$toasted.error(response.message);
        }
     
    }
    } else {
        this.$toasted.error(message);
      }
    },
    async saveStudentGradeContribution() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid){
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
      
       

        const response = await ViService.viXPost(
          "/academic/studentcontributionGrade/save",
          this.studentContributionList,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.$emit('childEvent', 'Hello from Child!');
          this.getContributionAction()
          // this.getStudentContributions()
          // this.getStudentGradeContributions()
        } else {
          this.$toasted.error(response.message);
        }
     
    }
    } else {
        this.$toasted.error(message);
      }
    },
    
    
  },
  components: {
    FullCalendar,
  },
};
</script>
<style>
.switchNew {
  position: relative;
  display: inline-block;
  width: 68px;
  height: 25px;
  margin-bottom: -4px;
}

.switchNew input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderNew {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderNew:before {
  color: red;
  position: absolute;
  content: "";
  height: 18px;
  width: 21px;
  left: 13px;
  bottom: 4px;
  background-color: blue;
  transition: .4s;
}



input:focus+.sliderNew {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.sliderNew:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliderNews */
.sliderNew.round {
  border-radius: 34px;
}

.sliderNew.round:before {
  border-radius: 50%;
}
</style>
<!-- <script>
    document.getElementById('myNumber').addEventListener('wheel', function(event) {
      // Prevent the default behavior of the wheel event, which is to increase/decrease the value
      event.preventDefault();
    });
  </script> -->
